import React, { useCallback, useEffect, useState } from "react"
import { useEvents } from "../../hooks"

import "./TestEvent.scss"

/**
 * デバッグ用コンポーネント。
 * RootWrapperで埋め込まれたEventEmitterを使って各種イベントを発火する。
 */
const TestEvents = () => {
  const events = useEvents()
  const [isOpen, setIsOpen] = useState(false)

  // TestEventsの表示/非表示を切り替える
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "0") {
        setIsOpen(!isOpen)
      }
    }

    if (typeof window !== "undefined") {
      window.addEventListener("keydown", onKeyDown)
      return () => window.removeEventListener("keydown", onKeyDown)
    }
  }, [isOpen])

  useEffect(() => {
    const alert = (msg: string) => window.alert(`Event received!: ${msg}`)
    events.on("message", alert)
    return () => {
      events.off("message", alert)
    }
  }, [events])

  // ボタンをクリックしたらmessageイベントを送信する
  const onClick = useCallback(() => events.emit("message", "yo yo"), [events])

  const handleTestClick = () => {
    events.emit("openVendor", `13`)
  }

  const handleTestClick2 = () => {
    events.emit("openVendor", `9475`)
  }

  const handleTestConfirmClick = () => {
    events.emit("requestMovePage", {
      url: "https://google.com/",
      title: "Google のウェブサイト",
      description: "だみー、だみー、だみー、だみー、ダミーテキストに移動します",
      label: "移動する",
    })
  }

  const handleTestMessageClick = useCallback(
    () => events.emit("talkToVendor", `13`),
    [events]
  )

  const handleTestAdMessageClick = useCallback(
    () => events.emit("talkToVendor", `ad-727`),
    [events]
  )

  const handleTestKashikanbanMessageClick = useCallback(
    () => events.emit("talkToVendor", `ad-wanted`),
    [events]
  )

  const filterBooths = useCallback(() => {
    const ids = []
    const threshold = Math.random() * 0.5 + 0.01
    for (let i = 0; i < 200; i++) {
      if (Math.random() < threshold) {
        ids.push(i.toString())
      }
    }
    events.emit("filterBooths", ids)
    // console.log(">> Filtering booths:", ids)
  }, [events])

  const disableFilterBooths = useCallback(() => {
    events.emit("filterBooths", undefined)
  }, [events])

  if (!isOpen) {
    return null
  }

  return (
    <div className={`TestEvent`}>
      <h3>Debug: “0” キーでトグル表示</h3>
      <div className={`TestEvent__buttons`}>
        <button type="button" onClick={onClick}>
          Emitter Test!
        </button>
        <button onClick={handleTestMessageClick}>メッセージ表示</button>
        <button onClick={handleTestAdMessageClick}>アド用メッセージ表示</button>
        <button onClick={handleTestKashikanbanMessageClick}>
          貸し看板アド用
        </button>

        <button onClick={handleTestClick}>Exhibitor 13</button>

        <button onClick={handleTestClick2}>Exhibitor 9475</button>
        <button onClick={handleTestConfirmClick}>
          Emitter: 外部サイトに移動
        </button>
        <button onClick={filterBooths}>Emitter: ブース検索（ランダム）</button>
        <button onClick={disableFilterBooths}>Emitter: ブース検索解除</button>
      </div>
    </div>
  )
}
export default TestEvents
