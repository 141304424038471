// extracted by mini-css-extract-plugin
export var hitarea = "Message-module--hitarea--V2OTk";
export var container = "Message-module--container--2dWlb";
export var content = "Message-module--content--1rkDn";
export var head = "Message-module--head--3muu_";
export var name = "Message-module--name--ay0vb";
export var icon = "Message-module--icon--113aB";
export var text = "Message-module--text--dlaoz";
export var btn = "Message-module--btn--3JlVy";
export var btn__wrap = "Message-module--btn__wrap--2qzK9";
export var nextPrev = "Message-module--nextPrev--2D7qN";
export var close = "Message-module--close--cjjnS";
export var linkLineloop = "Message-module--link-lineloop--2IsdS";
export var keyGnav = "Message-module--key-gnav--1kLjr";