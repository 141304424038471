import React, { useEffect, useCallback } from "react"
import { useEvents } from "../../hooks"
import { useState } from "react"
import * as css from "./MovePageConfirm.module.scss"
import { MovePageParams } from "../../commonTypes"

function MovePageConfirm() {
  const events = useEvents()
  const [confirmParam, setConfirmParam] = useState<MovePageParams | null>(null)

  const doConfirm = useCallback((param: MovePageParams) => {
    setConfirmParam({ ...param }) //オブジェクトを新しく作り直す必要がある
  }, [])

  useEffect(() => {
    events.on("requestMovePage", doConfirm)
    return () => {
      events.off("requestMovePage", doConfirm)
    }
  }, [events, doConfirm])

  const cancelHandler = () => {
    events.emit("cancelMovePage")
    setConfirmParam(null)
  }

  const okHandler = useCallback(
    e => {
      if (typeof window === "undefined") {
        return
      }
      if (confirmParam === null) {
        return
      }

      if (confirmParam.eventName) {
        events.emit(confirmParam.eventName)
        setConfirmParam(null)
      } else {
        window.open(confirmParam.url, "_blank")
      }
    },
    [confirmParam, events]
  )

  return (
    <div>
      {confirmParam && (
        <>
          <div className={css.base}>
            <div className={css.base__title}>{confirmParam.title}</div>
            <p className={css.base__description}>{confirmParam.description}</p>
            <button className={css.base__enter} onClick={okHandler}>
              {confirmParam.label ?? "入場する"}
            </button>
            <button className={css.base__cancel} onClick={cancelHandler}>
              <span className={`u-reader`}>Cancel</span>
            </button>
          </div>
          <button className={css.hitarea} onClick={cancelHandler}>
            <span className={`u-reader`}>Cancel</span>
          </button>
        </>
      )}
    </div>
  )
}

export default MovePageConfirm
