import React, { useContext, useEffect } from "react"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { loadViewCount } from "../api"

const Counter = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    if (!state.accessCount) {
      const loadAccessCount = async () => {
        const loadCount = await loadViewCount()
        dispatch({
          type: "ACCESS_COUNT_LOADED",
          accessCount: loadCount,
        })
      }
      loadAccessCount()
    }
  }, [state.accessCount, dispatch])

  if (!state.accessCount) {
    return <span>---</span>
  }
  return <span>{state.accessCount}</span>
}
export default Counter
