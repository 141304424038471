import React, { useContext, useCallback } from "react"
import * as css from "./StageSwitcher.module.scss"
import { useEvents } from "../../hooks"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

type StageSwitcherProps = {
  lang: string
  stage: string
}

function StageSwitcher(props: StageSwitcherProps) {
  const events = useEvents()
  const dispatch = useContext(GlobalDispatchContext)

  const stageSelectOpen = useCallback(() => {
    dispatch({ type: "STAGE_SELECT_ON" })
    events.emit("playSE", `se2`)
  }, [dispatch, events])

  return (
    <div className={css.main}>
      <button className={`guide-home-stageSwitcher`} onClick={stageSelectOpen}>
        <img src={`/stageIcons/${props.stage}.png`} alt="" />
        <span>
          {props.stage.toUpperCase()}{" "}
          <i className={`tabficon-small-arrow-down`} />
        </span>
      </button>
    </div>
  )
}

export default StageSwitcher
