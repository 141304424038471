// extracted by mini-css-extract-plugin
export var logo = "LoadingMask-module--logo--3zK3x";
export var wrapper = "LoadingMask-module--wrapper--RkW0J";
export var background = "LoadingMask-module--background--2WK6Z";
export var version = "LoadingMask-module--version--IEIBZ";
export var simpleLink = "LoadingMask-module--simpleLink--mpz87";
export var linkLineloop = "LoadingMask-module--link-lineloop--3hUNe";
export var lang = "LoadingMask-module--lang--3T6mk";
export var sound = "LoadingMask-module--sound--3bmvS";
export var title_svg = "LoadingMask-module--title_svg--bq8uX";
export var visitors = "LoadingMask-module--visitors--M2Y4Z";
export var keyGnav = "LoadingMask-module--key-gnav--3NLcY";