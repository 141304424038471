import React, { useContext, useEffect, useCallback } from "react"
import Layout from "../layouts"
import { LoadingMask } from "../components/LoadingMask"
import MovePageConfirm from "../components/helper/MovePageConfirm"
import Message from "../components/Message"
import { graphql, useStaticQuery } from "gatsby"
import SearchExhibitorsTrigger from "../components/search/SearchExhibitorsTrigger"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { Language, Location } from "../commonTypes"
import SelectStage from "../components/SelectStage"
import * as css from "./FrontPage.module.scss"
import TestEvents from "../components/test/TestEvents"
import StageSwitcher from "../components/menu/StageSwitcher"
import ToggleSound from "../components/ToggleSound"
import GuideTour from "../components/helper/GuideTour"
import { Link } from "gatsby"
import ClosingMessage from "../components/ClosingMessage"

type FrontPageProps = {
  location: Location
  lang: Language
}

function FrontPage(props: FrontPageProps) {
  // 3D のローディング状況を知るために Global なコンテキストを利用
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `
  )
  const regex = /(\/en)?\/([a-z]+)(\/)?/i
  const stageName = props.location.pathname.replace(regex, "$2")

  useEffect(() => {
    const theStage =
      props.location.pathname === "/" || props.location.pathname === "/en/"
        ? null
        : stageName
    if (theStage && theStage !== state.stage) {
      dispatch({ type: `CHANGE_STAGE`, stage: stageName })
    }
  }, [state, dispatch])

  const isTopPage =
    props.location.pathname === "/" || props.location.pathname === "/en/"

  let pageTitle = `HOME`
  if (state.stage) {
    pageTitle = `${state.stage.toUpperCase()} STAGE`
  }

  const doCloseModal = useCallback(() => {
    dispatch({ type: `CHANGE_CLOSING_REMARKS`, displayClosingRemarks: false })
  }, [state, dispatch])

  // トップページかつ初回ロードのときはロゴをアニメーションしない
  const shouldAnimateLogo = !(isTopPage && state.loadingWorld === undefined)

  return (
    <Layout
      lang={props.lang}
      location={props.location}
      title={pageTitle}
      show3dLayer={true}
      invertColor={isTopPage}
    >
      <div>
        <MovePageConfirm />

        <Message lang={props.lang} />

        <div className={css.FrontPage__searchBtn + ` guide-home-search`}>
          <SearchExhibitorsTrigger lang={props.lang} invertColor={isTopPage} />
        </div>
        <div className={css.FrontPage__soundBtn + ` guide-home-sound`}>
          <ToggleSound invertColor={isTopPage} />
        </div>

        {!!process.env.GATSBY_TEST_MODE && <TestEvents />}

        {/* 右下はてなボタン */}

        {/*<Helper lang={props.lang} />*/}
      </div>

      {/*  ローディングレイヤーの ON / OFF を GlobalState の値を見て変更します */}
      <LoadingMask
        isLoading={!state.webGlLoaded}
        version={site.siteMetadata.version}
        lang={props.lang}
        animate={shouldAnimateLogo}
      />

      {state.displayClosingRemarks && (
        <ClosingMessage lang={props.lang} onClose={doCloseModal} />
      )}

      {isTopPage ? (
        <>
          {/* トップページのときだけステージセレクト、言語切替を表示 */}
          <SelectStage lang={props.lang} />
          <div>
            <div className={css.FrontPage__langBtn}>
              {props.lang === "ja" ? (
                <Link to={`/en/`}>EN</Link>
              ) : (
                <Link to={`/`}>JA</Link>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {/* トップページ以外のときはステージ選択スイッチ、チュートリアルを表示 */}
          <StageSwitcher lang={props.lang} stage={stageName} />
          <div className={css.transparentBoothArea + ` guide-home-boothArea`} />
          <GuideTour lang={props.lang} type={`HOME`} delay={1000} />
        </>
      )}
    </Layout>
  )
}

export default FrontPage
