import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import * as css from "./PrevNextLink.module.scss"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { Exhibitor, loadExhibitors } from "../../api"
import { Link } from "gatsby"
import { decodeHTMLEntities } from "../../utils/decodeHTMLEntities"

type PrevNextLinkProps = Readonly<{
  current: Exhibitor
  sameStage?: boolean
  lang?: string
  onPrevClick?: (arg1: Exhibitor | { id: string; name: string }) => void
  onNextClick?: (arg1: Exhibitor | { id: string; name: string }) => void
}>

const PrevNextLink: React.FC<PrevNextLinkProps> = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    if (!state.exhibitors) {
      // console.log(`GlobalContext に Exhibitors 情報が見つかりません`)
      const loadJson = async () => {
        const loadedExhibitor = await loadExhibitors()
        dispatch({
          type: "EXHIBITORS_INDEX_LOADED",
          exhibitors: loadedExhibitor,
        })
        // console.log(`GlobalContext に Exhibitors を登録しました`)
      }
      loadJson()
    }
  }, [state.exhibitors, dispatch])

  if (!state.exhibitors) {
    return (
      <div className={css.prevNextLink}>
        <div>Loading...</div>
      </div>
    )
  }

  // console.log("このコンポーネントで使う用の出展者リストを作成")
  // console.log("current", props.current)
  // console.log("このコンポーネントで使う用の出展者リストを作成")
  // このコンポーネントで使うようのリストを作成
  let localExhibitorsList: Exhibitor[] = state.exhibitors
  if (props && props.sameStage) {
    const currentStage = props.current.stage
    // console.log(props)
    // console.log(`現在のステージは ${currentStage}`)
    localExhibitorsList = []
    const stateExhibitors: Exhibitor[] = state.exhibitors
    stateExhibitors.forEach((exhibitor, i) => {
      if (exhibitor.stage === currentStage) {
        localExhibitorsList.push(exhibitor)
        // console.log(
        //   `ID ${exhibitor.id} のステージは ${exhibitor.stage}, 一致するのでリストに加えます`
        // )
      }
    })
  }

  // console.log(`ローカルで使うリストは, `, localExhibitorsList)

  let prevExhibitor: Exhibitor | { id: string; name: string; name_en: string } =
    {
      id: "",
      name: "",
      name_en: "",
    }
  let nextExhibitor: Exhibitor | { id: string; name: string; name_en: string } =
    {
      id: "",
      name: "",
      name_en: "",
    }

  // 前後の出展者を探す
  localExhibitorsList.forEach((exhibitor, i) => {
    // メッセージパネル内ではprops.current.idが生きている
    // 詳細ページ内だとprops.current.idの取得にコケてprev/nextが表示出来なくなっていたがprops.currentではidが取れたので暫定処置
    // prev/nextでリンクされている出展者がメッセージパネルと詳細ページで違う？
    let currentId = props.current.id ? props.current.id : props.current
    if (exhibitor.id === currentId) {
      if (localExhibitorsList[i - 1]) {
        prevExhibitor = localExhibitorsList[i - 1]
      }

      if (localExhibitorsList[i + 1]) {
        nextExhibitor = localExhibitorsList[i + 1]
      }
    }
  })
  // console.log(`出展者情報は ${state.exhibitors.length} 件です`)
  // console.log(`現在の ID は ${prop.current} です`)
  // console.log(`一つ前の ID は ${prevExhibitor.exhibitorId} です`)
  // console.log(`一つ次の ID は ${nextExhibitor.exhibitorId} です`)

  const dirLang = props.lang === "ja" ? "" : "/en"

  let prevEl = <></>
  if (prevExhibitor.id !== "") {
    prevEl = (
      <Link
        to={`${dirLang}/exhibitors/${prevExhibitor.id}/`}
        className={`TextBtn TextBtn--back`}
      >
        <span>
          {decodeHTMLEntities(
            props.lang === `ja` ? prevExhibitor!.name : prevExhibitor!.name_en
          )}
        </span>
      </Link>
    )
  }

  let nextEl = <></>
  if (nextExhibitor.id !== "") {
    nextEl = (
      <Link
        to={`${dirLang}/exhibitors/${nextExhibitor.id}/`}
        className={`TextBtn TextBtn--next`}
      >
        <span>
          {decodeHTMLEntities(
            props.lang === `ja` ? nextExhibitor!.name : nextExhibitor!.name_en
          )}
        </span>
      </Link>
    )
  }

  if (prevExhibitor && props.onPrevClick && prevExhibitor.id !== "") {
    prevEl = (
      <button
        onClick={() => {
          props.onPrevClick && props.onPrevClick(prevExhibitor)
        }}
        className={`TextBtn TextBtn--back`}
      >
        <span>
          {decodeHTMLEntities(
            props.lang === `ja` ? prevExhibitor!.name : prevExhibitor!.name_en
          )}
        </span>
      </button>
    )
  }

  if (nextExhibitor && props.onNextClick && nextExhibitor.id !== "") {
    nextEl = (
      <button
        onClick={() => {
          props.onNextClick && props.onNextClick(nextExhibitor)
        }}
        className={`TextBtn TextBtn--next`}
      >
        <span>
          {decodeHTMLEntities(
            props.lang === `ja` ? nextExhibitor!.name : nextExhibitor!.name_en
          )}
        </span>
      </button>
    )
  }

  return (
    <div className={css.prevNextLink}>
      <div className={``}>{prevEl}</div>
      <div className={``}>{nextEl}</div>
    </div>
  )
}

export default PrevNextLink
