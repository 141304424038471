import React, { useEffect } from "react"
import * as css from "./Message.module.scss"
import { decodeHTMLEntities } from "../utils/decodeHTMLEntities"
import { nl2br } from "../utils/nl2br"
import PaymentIcons from "./exhibitor/PaymentIcons"
import { useEvents } from "../hooks"

function MessageContent(prop) {
  const postData = prop.postData

  const getTheMessage = () => {
    // メッセージ取得
    return <p>{nl2br(decodeHTMLEntities(prop.message))}</p>
  }
  const events = useEvents()
  const doCloseMenu = () => {
    events.emit("requestLeaveVendor")
  }

  useEffect(() => {
    const head = document.querySelector("#js-message-head")
    const img = document.querySelector("#js-message-img")
    const name = document.querySelector("#js-message-name")
    // アイコン画像より名前が短い場合は名前を上下中央に
    if (img && name.clientHeight < img.clientHeight) {
      head.dataset.flex = "true"
    }
  }, [])

  // console.log(`POST DATA`, postData)

  const exhibitorName = prop.lang === `ja` ? postData.name : postData.name_en

  return (
    <>
      <div
        className={css.head}
        data-motion="up-inner-1"
        id="js-message-head"
        data-flex="true"
      >
        {postData.icon && (
          <figure className={css.icon} id="js-message-img">
            <img src={postData.icon} alt={exhibitorName} />
          </figure>
        )}
        <div className={css.name} id="js-message-name">
          <h2>{decodeHTMLEntities(exhibitorName)}</h2>
          {(postData.payment?.includes(1) ||
            postData.payment?.includes(2) ||
            postData.payment?.includes(3)) && (
            <div>
              <PaymentIcons
                paypal={postData.payment.includes(3)}
                amazon={postData.payment.includes(2)}
                zozo={postData.payment.includes(1)}
                showDisabledIcon={false}
              />
            </div>
          )}
        </div>
        <div className={css.close}>
          <button className={"btn__close"} onClick={doCloseMenu}>
            Close
          </button>
        </div>
      </div>
      <div className={css.text} data-motion="up-inner-3">
        <div>{getTheMessage()}</div>
      </div>
    </>
  )
}

export default MessageContent
