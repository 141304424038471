module.exports = [
  {
    ad: true,
    id: "ad-727",
    name: "727 Cosmetics",
    name_en: "727 Cosmetics",
    country: "Japan",
    url: "https://www.727.co.jp",
    icon: "/images/ad/ad-727--sq.jpg",
    message: [
      "こんにちは！\n" +
        "この看板に見覚えはありますか？\n" +
        "そうです！新幹線から見えるアノ看板。「一体、何の会社？」とよく問い合わせをいただきますが\n" +
        "わたしたちは、大阪にある美容室専売の化粧品メーカーで「セブンツーセブン」といいます。\n" +
        "商品や会社、看板のことなど、よろしければご覧になってくださいね。" +
        "\n" +
        "\n" +
        "Hello!\n" +
        "Do you recognize this sign? \n" +
        "You may have seen it from the window of a passing Shinkansen train.\n" +
        '"What kind of company is it?" People often ask... \n' +
        "727 is a company based in Osaka, specializing in cosmetics used in beauty salons. \n" +
        "Please feel free to visit our website if you would like to learn more about our products, company, and our signboard. ",
    ],
  },
  {
    ad: true,
    id: "ad-zozo",
    name: "ZOZOVILLA",
    name_en: "ZOZOVILLA",
    country: "Japan",
    url: "https://zozo.jp/search/?p_tpcsid=1532983",
    icon: "/images/ad/ad-zozo--sq.jpg",
    message: [
      "海外を拠点とするオンライン会場出展者の書籍の一部は、ファッション通販サイト「ZOZOTOWN」上のラグジュアリー＆デザイナーズゾーン「ZOZOVILLA」にてご購入いただけます。\n" +
        "\n" +
        "Part of the books presented by overseas-based exhibitors at the Online Venue will be available for purchase on the Luxury & Designers zone, ZOZOVILLA, within the fashion retail website ZOZOTOWN. ",
    ],
  },
  {
    ad: true,
    id: "ad-mot",
    name: "東京都現代美術館",
    name_en: "Museum of Contemporary Art Tokyo",
    country: "Japan",
    url: "https://www.mot-art-museum.jp/",
    icon: "/images/ad/ad-mot--sq.jpg",
    message: [
      "開催予定の展覧会\n" +
        "Viva Video! 久保田成子展\n" +
        "2021年11月13日（土）－ 2022年2月23日（水・祝）\n" +
        "企画展示室 3F\n" +
        "\n" +
        "Upcoming Exhibition\n" +
        "Viva Video! Shigeko Kubota Exhibition\n" +
        "2021.11.13 Sat - 2022.2.23 Wed\n" +
        "Exhibition Gallery 3F",

      "開催予定の展覧会\n" +
        "MOTコレクション　Journals　日々、記す vol.2\n" +
        "2021年11月13日（土）－ 2022年2月23日（水・祝）\n" +
        "コレクション展示室　1F / 3F\n" +
        "\n",
      +"Upcoming Exhibition\n" +
        "MOT Collection: Journals vol.2\n" +
        "2021.11.13 Sat - 2022.2.23 Wed\n" +
        "Collection Gallery 1F / 3F",

      "開催予定の展覧会\n" +
        "クリスチャン・マークレー\n" +
        "トランスレーティング［翻訳する］\n" +
        "2021年11月20日（土）－ 2022年2月23日（水・祝）\n" +
        "企画展示室 1F\n" +
        "\n" +
        "Upcoming Exhibition\n" +
        "Christian Marclay Translating\n" +
        "11.20 Sat - 2022.2.23 Wed\n" +
        "Exhibition Gallery 1F",

      "開催予定の展覧会\n" +
        "ユージーン・スタジオ 新しい海\n" +
        "\n" +
        "Upcoming Exhibition\n" +
        "EUGENE STUDIO After the rainbow",
    ],
  },
  {
    ad: true,
    id: "ad-parkhotel",
    name: "芝パークホテル",
    name_en: "Shiba Park Hotel",
    country: "Japan",
    url: "https://www.shibaparkhotel.com/tokyo-art-book-fair-2021/",
    icon: "/images/ad/ad-parkhotel--sq.jpg",
    message: [
      "大人のライブラリー空間に泊まれるチャンス！\n" +
        "応募は簡単　リニューアルした芝パークホテルのディナー付きペア宿泊券をプレゼント！\n" +
        "\n" +
        "A chance to enjoy a comfortable stay with access to a new library hotel! \n" +
        "Easy to apply—Win an overnight lodging plus dinner for two at the newly renovated Shiba Park Hotel.",

      "この広告を見ていただいた方全員が対象！\n" +
        "レストラン ザ ダイニングのお飲み物を50％オフでご利用できます。\n" +
        "\n" +
        "For anyone who sees this ad!\n" +
        "Enjoy 50% discount on beverages at the restaurant, The Dining.",

      "港区芝公園に佇むライブラリーホテル\n" +
        "クリエイティブに、五感を高めて、心を満たす。多種多様な書籍約1200冊を楽しめます！\n" +
        "お得な情報はこちら\n" +
        "\n" +
        "A Library Hotel located in Shiba Park, Minato-ku, Tokyo. \n" +
        "Elevate your senses and heighten your creative mind. Boasts over 1,200 hand-selected books of great variety!\n" +
        "For special offers see: ",
    ],
  },
  {
    ad: true,
    id: "ad-artsticker",
    name: "ArtSticker (The Chain Museum Inc.)",
    name_en: "ArtSticker (The Chain Museum Inc.)",
    country: "Japan",
    url: "https://bit.ly/3lImqWs",
    icon: "/images/ad/ad-artsticker--sq.jpg",
    message: [
      "アート鑑賞の「一連の体験をつなぐ」 アート・コミュニケーションプラットフォーム、「ArtSticker（アートスティッカー）」。\n" +
        "\n" +
        "TABFオフライン会場の「入場券（時間予約制）」チケットはこちらからお申込みいただけます。\n" +
        "また、ArtStickerアプリをダウンロードいただくと、出展者のPRメッセージが音声で聴ける「音声ガイド」も無料でご案内しています。\n",
      "\n" +
        "\n" +
        'ArtSticker is a "Communication Platform about Art" operated by The Chain Museum Co., Ltd. \n' +
        "\n" +
        "We provide ticketing system for your Offline Venue entry.\n" +
        "Moreover, you can enjoy listening to exhibitors' PR messages with our free audio guide by downloading the ArtSticker app",
    ],
  },
  {
    ad: true,
    id: "ad-inuuniq",
    name: "株式会社イニュニック",
    name_en: "inuuniq Co., Ltd",
    country: "Japan",
    url: "https://inuuniq.co.jp/",
    icon: "/images/ad/ad-inuuniq--sq.jpg",
    message: [
      "オンデマンド、オフセット印刷対応。WEB注文にて全国への発送可。\n" +
        "きめ細かいサービスでお客様のニーズにお答えします。\n" +
        "弊社サイト「PRINT BINDING」では\n" +
        "これまでに印刷・製本した作品（一部）の仕様をご紹介しています。\n\n" +
        "Print-on-Demand and Offset Printing Services. Order printing online, nationwide shipping available. We offer a wide range of services to meet the needs of our customers.\n" +
        "Please take a look through our website PRINT BINDING to see some of our past printing and binding projects.",
    ],
  },
  {
    ad: true,
    id: "ad-shabf",
    name: "SHANGHAI ART BOOK FAIR",
    name_en: "SHANGHAI ART BOOK FAIR",
    country: "Japan",
    url: "https://shanghaiartbookfair.com/",
    icon: "/images/ad/ad-shabf--sq.jpg",
    message: [
      "SHANGHAI ART BOOK FAIR 10.28 - 10.31, M50, Shanghai!",
      "今年の上海アートブックフェアには、17カ国から165組の出展者が集結します。\n " +
        "SHABF 2021 Welcomes 195 Exhibitors from 17 Countries!",
      "FOLLOW US AT INSTAGEAM @shanghaiartbookfair ~!",
      "Want to be an exhibitor of 2022? Follow us at instagram~! @shanghaiartbookfair",
    ],
  },
  {
    ad: true,
    id: "ad-wanted",
    name: "貸看板",
    name_en: "Rental Signboard",
    country: "Japan",
    url: "mailto:info@tokyoartbookfair.com",
    icon: "/images/ad/ad-wanted--sq.jpg",
    message: [
      "TABFでは、多彩な協賛プランをご用意しています。\n" +
        "資料をご希望の方はこちらからお問い合わせください。\n" +
        "info@tokyoartbookfair.com\n\n" +
        "TABF offers a wide variety of sponsorship opportunities. To know more, contact us at info@tokyoartbookfair.com",
    ],
  },
]
