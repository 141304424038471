import React, { useEffect, useCallback, useState } from "react"
import { useEvents } from "../hooks"
import * as css from "./Message.module.scss"
import MessageContent from "./MessageContent"
import { FormattedMessage } from "react-intl"
import PrevNextLink from "./exhibitor/PrevNextLink"
import ad from "../data/ad"
import GuideTour from "./helper/GuideTour"

/**
 * ブースフォーカス時のメッセージを表示するコンポーネント
 */
function Message(prop) {
  const [postData, setPostData] = useState(null) // JSONデータ
  const [message, setMessage] = useState(null) // JSONデータ
  // event hook
  const events = useEvents()

  const [open, setOpen] = useState(false)

  /**
   * メッセージモーダル表示
   * @param {Number} id メッセージ表示させるID
   */
  const doMessage = useCallback(
    id => {
      const isAd = String(id).indexOf(`ad`) >= 0

      if (isAd) {
        // 該当のコメントを探す
        const hitAd = ad.find(obj => {
          return obj.id === id
        })
        setMessage(getTheMessageAndIncrement(hitAd))
        setPostData(hitAd)
        setOpen(true)
      } else {
        setPostData({ loading: true })
        const url = `https://d3lm2i23uzukol.cloudfront.net/exibitor/${id}/single.json`
        fetch(url, {
          mode: "cors",
        })
          .then(res => {
            return res.json()
          })
          .catch(err => {
            // console.log("rejectedの処理です")
            // console.error(err)
          })
          .then(resJson => {
            // 同じタイミングで state が更新されるようにここで反映
            setMessage(getTheMessageAndIncrement(resJson))
            setPostData(resJson)
            setOpen(true)

            return false
          })
      }
    },
    [setPostData, setMessage]
  )

  /**
   * メッセージのカウントを増やします。
   * @constructor
   */
  const getTheMessageAndIncrement = json => {
    const lastUpdated = localStorage.getItem("messageLastUpdatedTime") // ローカルストレージに時間を記録
    const shouldUpdate =
      lastUpdated && new Date().getTime() - lastUpdated > 1000

    const messageId = json.id
    const messageStr = localStorage.getItem("message")

    let messageObj = {}
    if (messageStr) {
      messageObj = JSON.parse(messageStr)
    }
    // console.log(`messageStr: `, messageStr)
    // console.log(`Local Storage 内のメッセージ: `, messageObj)
    // console.log(`現在の messageId: `, messageId)

    // メッセージが一つも登録されていない場合は空文字でリターン
    let isEmpty = true
    for (let i = 0; i < json.message.length; i += 1) {
      if (json.message[i].length > 0) {
        isEmpty = false
        break
      }
    }
    if (isEmpty) {
      return "準備中 / Under preparation"
    }

    let count = 0 // メッセージ表示回数
    if (messageObj && messageObj.hasOwnProperty(messageId)) {
      if (shouldUpdate) {
        // インクリメントする
        count = messageObj[messageId] + 1
        if (count > json.message.length - 1) count = 0
        // 空のコメントは飛ばす
        while (json.message[count] === "") {
          // console.log(`The message no ${count} is empty, skip it.`)
          count += 1
          if (count > json.message.length - 1) count = 0
        }
      } else {
        // console.log(`This is continuous click, comments will not increment`)
        count = messageObj[messageId]
      }
    }
    messageObj[messageId] = count
    localStorage.setItem("message", JSON.stringify(messageObj)) // ローカルストレージ登録
    localStorage.setItem("messageLastUpdatedTime", new Date().getTime()) // ローカルストレージに時間を記録

    return json.message[count] // メッセージ取得
  }

  /**
   * ブースを見る
   * @param {Object} e イベントオブジェクト
   */
  const gotoDetail = useCallback(
    e => {
      events.emit("openVendor", postData.id, prop.lang)
    },
    [events, postData, prop.lang]
  )

  /**
   * 立ち去る
   * @param {Object} e イベントオブジェクト
   */
  const closeMessage = useCallback(
    e => {
      if (postData) {
        events.emit("leaveVendor", postData.id)
        setPostData(null)
        setOpen(false)
      }
    },
    [events, postData]
  )

  const onChangeWorld = useCallback(worldId => {
    // ブース一覧から移動したらメッセージを消す
    if (worldId !== "booth") {
      setPostData(null)
    }
  }, [])

  const doPrevComment = useCallback(
    prevExhibitor => {
      setOpen(false)
      events.emit("talkToVendor", prevExhibitor.id)
      events.emit("moveToVendor", prevExhibitor.id)
    },
    [events]
  )

  const doNextComment = useCallback(
    nextExhibitor => {
      setOpen(false)
      events.emit("talkToVendor", nextExhibitor.id)
      events.emit("moveToVendor", nextExhibitor.id)
    },
    [events]
  )

  useEffect(() => {
    events.on("talkToVendor", doMessage)
    events.on("requestLeaveVendor", closeMessage)
    events.on("changeWorld", onChangeWorld)

    return () => {
      events.off("talkToVendor", doMessage)
      events.off("requestLeaveVendor", closeMessage)
      events.off("changeWorld", onChangeWorld)
    }
  }, [events, doMessage, closeMessage, onChangeWorld])

  if (!postData) {
    return <></>
  }

  if (postData.loading) {
    return (
      <>
        <div className={css.container}>
          <section>Loading</section>
        </div>
      </>
    )
  }

  const clsGuideNextPrev = postData.ad ? `` : ` guide-message-next-prev`

  return (
    <div>
      <div className={css.container} data-open={open}>
        <section className={css.content}>
          <GuideTour lang={prop.lang} type={`MESSAGE_MODAL`} delay={1000} />

          {
            <MessageContent
              postData={postData}
              message={message}
              lang={prop.lang}
            />
          }
          <div className={css.btn}>
            <ul className={`btn__wrap__col is-center`}>
              <li data-motion="up-inner-1" className={`sp-full`}>
                {/* 通常のブースのコメント　*/}
                {postData.icon && !postData.ad && (
                  <button
                    className={`simple-btn guide-message-showBooth`}
                    onClick={gotoDetail}
                  >
                    <FormattedMessage id={`showBooth`} />
                  </button>
                )}

                {/* 727 などの広告のコメント */}
                {postData.ad && (
                  <a
                    className={`simple-btn`}
                    href={postData.url}
                    target={`_blank`}
                    rel="noopener noreferrer"
                  >
                    {postData.url.indexOf(`mailto`) >= 0 ? (
                      <FormattedMessage id={`contact`} />
                    ) : (
                      <FormattedMessage id={`showDetail`} />
                    )}
                  </a>
                )}
              </li>
            </ul>
            <div className={css.nextPrev + clsGuideNextPrev}>
              {!postData.ad && (
                <PrevNextLink
                  current={postData}
                  lang={prop.lang}
                  onPrevClick={doPrevComment}
                  onNextClick={doNextComment}
                  sameStage={true}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <button onClick={closeMessage} className={css.hitarea}>
        <span className={`u-reader`}>close</span>
      </button>
    </div>
  )
}

export default Message
