import * as React from "react"
import * as style from "./VabfLogo.module.scss"

type VabfLogoProps = { animate: boolean }

export function VabfLogo({ animate }: VabfLogoProps) {
  return (
    <svg viewBox="0 0 483 579" className={style.wrapper}>
      <defs>
        <style>{".VabfLogo_inline_svg__cls-3{fill:#231f20}"}</style>
      </defs>
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <g id="VabfLogo_inline_svg__Layer_2" data-name="Layer 2">
          <g
            style={{
              mixBlendMode: "multiply",
            }}
            id="VabfLogo_inline_svg__\u30EC\u30A4\u30E4\u30FC_1"
            data-name="\u30EC\u30A4\u30E4\u30FC 1"
          >
            <path d="M194.89 538.82H20.34V538.46C19.41 538.4 18.34 538.36 16.96 538.36C14.7018 538.336 12.445 538.486 10.21 538.81C7.7235 539.172 5.21253 539.339 2.7 539.31C1.7 539.31 0.79 539.31 0 539.31V568.31C0.79 568.31 1.67 568.36 2.7 568.36C5.21253 568.389 7.7235 568.222 10.21 567.86C12.445 567.536 14.7018 567.386 16.96 567.41C18.33 567.41 19.41 567.41 20.34 567.51V567.86H194.89V565.54H258.3V536.54H194.89V538.82Z" />
            <path d="M10.21 270C12.4441 269.667 14.7013 269.513 16.96 269.54C18.33 269.54 19.41 269.54 20.34 269.64V270.21H194.89V267.89H227.89V238.89H194.89V241.21H20.34V240.62C19.41 240.56 18.34 240.53 16.96 240.53C14.7016 240.501 12.4445 240.651 10.21 240.98C7.72296 241.337 5.21239 241.504 2.7 241.48C1.7 241.48 0.79 241.48 0 241.42V270.47C0.79 270.47 1.67 270.52 2.7 270.52C5.21286 270.536 7.72333 270.362 10.21 270V270Z" />
            <path d="M482.45 273.91H234.06V302.94H482.45V273.91Z" />

            <path d="M292.25 578.57H331.45V471.53H438.22V440.62H331.45V368.32H443.9V337.07H292.25V578.57Z" />
            <path
              className={animate ? style.anim_a : ""}
              d="M99.8897 96.04H101.3V227.24H140.14V96.04H138.76V29.15H210.21V0H28.4297V29.15H99.8897V96.04Z"
            />
            <path
              className={animate ? style.anim_b : ""}
              d="M303.28 202.54H408.22L429.83 258.92H473.37L436.24 170.34H436.54L379 33.1201H358.82V34.0001H334.27L309 96.0001H310.59L280.27 170.3H279.33L243.18 258.88H282L303.28 202.54ZM343.83 96.0001H342.3L353.7 65.0001L358.82 78.5001V72.8301L395.82 170.34H395.51L397.27 174.98H313.93L315.65 170.32H316.5L343.83 96.0001Z"
            />
            <path
              className={animate ? style.anim_f : ""}
              d="M194.89 385.88V383.56C202.07 375.14 206.14 364.19 206.14 350.4C206.14 335.2 201.91 323.8 194.89 315.34V317.66C180.89 300.85 155.89 295.66 130.65 295.66H44.8203V522.17H129.4C153.14 522.17 178.15 517.68 194.89 504.01V501.72C206.89 491.95 214.59 477.48 214.59 456.58C214.59 437.29 207.2 423.38 194.89 414.17V416.48C186.61 410.29 176.13 406.22 164.16 404.08C177 400.44 187.56 394.48 194.89 385.88ZM174.81 456.71C174.81 489.28 146.94 493.71 120 493.71H82.7203V419.12H126.58C153.2 419.12 174.81 426.64 174.81 456.71ZM121.56 392.18H82.7203V324.18H124.72C146.96 324.18 167 332.32 167 357.07C167 384.35 145.68 392.18 121.56 392.18V392.18Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
