import React, { useCallback, useRef, useEffect } from "react"
import * as css from "./ClosingMessageProps.module.scss"

export type ClosingMessageProps = {
  lang: string
  onClose: () => void
}

/**
 * サイト内の音のオンオフさせます
 */
const ClosingMessage: React.FC<ClosingMessageProps> = ({ lang, onClose }) => {
  const gotoDetail = useCallback(() => {
    onClose()
  }, [])

  const baseEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    baseEl.current && baseEl.current.addEventListener("click", gotoDetail)

    return () => {
      baseEl.current && baseEl.current.removeEventListener("click", gotoDetail)
    }
  }, [baseEl])

  return (
    <div>
      <div className={css.base}>
        {lang === "ja" ? (
          <div className={css.base__box}>
            <p>
              TOKYO ART BOOK FAIR 2021 Online
              Venueにお越しいただきまして、ありがとうございました！
            </p>
            <p>
              最終日のコアタイムは、10月31日18時を持って終了いたしました。
              <br />
              今後も本サイトはアーカイブとして残しますので、会期中にすべてまわれなかった方も初めて訪れる方も、こちらのサイトでアートブックとの出会いを楽しんでいただければ幸いです。
            </p>
            <p className={css.btnBox}>
              <button
                className={`simple-btn guide-message-showBooth`}
                onClick={gotoDetail}
              >
                OK
              </button>
            </p>
          </div>
        ) : (
          <div className={css.base__box}>
            <p>
              Thank you everyone for coming to TOKYO ART BOOK FAIR 2021’s Online
              Venue! <br />
              Our last core time has just closed at 6:00 PM October 31st.
            </p>
            <p>
              However, the website will still be open as an archive so even if
              you couldn’t go around all the booths, you can carry on your
              amazing art book encounters here!
            </p>
            <p className={css.btnBox}>
              <button
                className={`simple-btn guide-message-showBooth`}
                onClick={gotoDetail}
              >
                OK
              </button>
            </p>
          </div>
        )}
      </div>
      <div className={css.hitArea} ref={baseEl} />
    </div>
  )
}
export default ClosingMessage
